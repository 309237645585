/* footer.css */
.container {
  /* max-width: 1200px; */
  margin: 0 auto;
  padding: 0 20px;
}

/* Newsletter Section */
.newsletter {
  background-color: #00018e;
  padding: 50px 0;
  color: #fff;
}

.newsletter-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.newsletter-text h1 {
  font-size: 28px;
  margin-bottom: 10px;
  font-weight: 600;
}

.newsletter-text span {
  color: #ffffff99;
}

.newsletter-form {
  display: flex;
  min-width: 300px;
  flex-shrink: 0;
}

.newsletter-form input {
  flex: 1;
  padding: 12px 15px;
  border: none;
  outline: none;
  border-radius: 4px 0 0 4px;
}

.submit-btn {
  background: #fff;
  color: #00018e;
  border: none;
  padding: 0 20px;
  cursor: pointer;
  border-radius: 0 4px 4px 0;
  transition: background-color 0.3s;
  margin: 0;
}

.submit-btn:hover {
  background-color: #f0f0f0;
}

/* Main Footer */
footer {
  background-color: #f8f8f8;
  padding-top: 60px;
}

.footer-content {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  gap: 40px;
  padding-bottom: 40px;
}

.footer-box h1 {
  color: #00018e;
  font-size: 24px;
  margin-bottom: 10px;
}

.footer-box span {
  color: #00018e;
  font-size: 14px;
  font-weight: 500;
}

.footer-box p {
  color: #666;
  margin: 20px 0;
  line-height: 1.6;
}

.footer-box h3 {
  font-size: 20px;
  margin-bottom: 25px;
  font-weight: 600;
}

.social-icons {
  display: flex;
  gap: 15px;
}



.icon:hover {
  background-color: #0000b966;
}

/* Links Section */
.links ul li {
  margin-bottom: 15px;
}

.links ul li a {
  color: #666;
  transition: color 0.3s;
  display: flex;
  align-items: center;
}

.links ul li a:before {
  content: "→";
  color: #00018e;
  margin-right: 10px;
}

.links ul li a:hover {
  color: #00018e;
}

/* Contact Section */
.contact ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  color: #666;
}

.contact ul li i {
  color: #00018e;
  width: 30px;
  margin-top: 5px;
}

.contact ul li a {
  color: #666;
  transition: color 0.3s;
}

.contact ul li a:hover {
  color: #00018e;
}

/* Footer Bottom */
.footer-bottom {
  background-color: #f0f0f0;
  padding: 20px 0;
  text-align: center;
  color: #666;
}

.footer-bottom i {
  color: #00018e;
  margin: 0 5px;
}

/* Responsive Design */
@media screen and (max-width: 968px) {
  .newsletter-content {
    flex-direction: column;
    text-align: center;
  }

  .newsletter-form {
    width: 100%;
    max-width: 500px;
  }
}

@media screen and (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .social-icons {
    justify-content: center;
  }

  .links ul li a {
    justify-content: center;
  }

  .contact ul li {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .contact ul li i {
    margin-bottom: 10px;
  }
}