/* EnquiryPopup.css */
.enquiry-dialog .dialog-paper {
    border-radius: 20px;
    overflow: hidden;
  }
  
  .enquiry-dialog .dialog-header {
    background: linear-gradient(135deg, #2196f3 0%, #1976d2 100%);
    padding: 24px 32px;
    position: relative;
    color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .enquiry-dialog .header-content {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .enquiry-dialog .header-icon {
    font-size: 40px;
    background: rgba(255, 255, 255, 0.2);
    padding: 12px;
    border-radius: 12px;
  }
  
  .enquiry-dialog .header-title {
    font-weight: 600;
    margin-bottom: 4px;
  }
  
  .enquiry-dialog .header-subtitle {
    opacity: 0.9;
    font-size: 0.95rem;
  }
  
  .enquiry-dialog .close-button {
    position: absolute;
    right: 16px;
    top: 16px;
    color: white;
    background: rgba(255, 255, 255, 0.1);
    transition: background 0.3s ease;
  }
  
  .enquiry-dialog .close-button:hover {
    background: rgba(255, 255, 255, 0.2);
  }
  
  .enquiry-dialog .dialog-content {
    padding: 32px;
    background: #f8f9fa;
    max-height: 450px;
    overflow: auto;
  }
  
  .enquiry-dialog .form-field {
    background: white;
    border-radius: 8px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .enquiry-dialog .form-field:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
  }
  
  .enquiry-dialog .form-field .MuiOutlinedInput-root {
    border-radius: 8px;
  }
  
  .enquiry-dialog .form-field .MuiOutlinedInput-notchedOutline {
    border-color: #e0e0e0;
  }
  
  .enquiry-dialog .form-field:hover .MuiOutlinedInput-notchedOutline {
    border-color: #1976d2;
  }
  
  .enquiry-dialog .message-field {
    background: white;
  }
  
  .enquiry-dialog .dialog-actions {
    padding: 16px 32px;
    background: white;
    border-top: 1px solid #eeeeee;
  }
  
  .enquiry-dialog .submit-button {
    background: linear-gradient(135deg, #2196f3 0%, #1976d2 100%);
    padding: 8px 24px;
    text-transform: none;
    font-size: 1rem;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(25, 118, 210, 0.2);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  
  .enquiry-dialog .submit-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(25, 118, 210, 0.3);
  }
  
  .enquiry-dialog .cancel-button {
    color: #666;
    text-transform: none;
    font-size: 1rem;
  }
  
  /* Responsive styling */
  @media (max-width: 600px) {
    .enquiry-dialog .dialog-header {
      padding: 20px 24px;
    }
  
    .enquiry-dialog .header-icon {
      font-size: 32px;
      padding: 8px;
    }
  
    .enquiry-dialog .dialog-content {
      padding: 24px;
    }
  
    .enquiry-dialog .dialog-actions {
      padding: 16px 24px;
    }
  }
  
  /* Animation for dialog appearance */
  .enquiry-dialog .MuiDialog-paper {
    animation: dialogFadeIn 0.3s ease-out;
  }
  
  @keyframes dialogFadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }