/* Base styles */
.price .items {
  padding: 40px;
  text-align: center;
}

.price h1 {
  font-size: 50px;
  margin: 20px 0;
  color: #00018e;
}

.price h1 span {
  font-size: 20px;
  margin-right: 5px;
  color: #000;
}

.price p {
  color: grey;
  margin: 40px 0;
}

/* FAQ Section */
.faq .container {
  max-width: 70%;
  border-radius: 5px;
  margin: 0 auto;
  padding: 20px;
}

.faq .box {
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.faq button {
  padding: 15px 20px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.faq h2 {
  font-weight: 500;
  font-size: 1.1rem;
  margin: 0;
  padding-right: 20px;
}

.faq p {
  padding: 20px;
  margin: 0;
  line-height: 1.6;
}

.faq .accordion:hover {
  background-color: #5d8eb2;
  color: #fff;
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
  .faq .container {
    max-width: 85%;
  }
  
  .price h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 768px) {
  .faq .container {
    max-width: 90%;
    padding: 15px;
  }
  
  .price .items {
    padding: 30px 20px;
  }
  
  .price h1 {
    font-size: 35px;
  }
  
  .faq h2 {
    font-size: 1rem;
  }
  
  .faq button {
    padding: 12px 15px;
  }
  
  .faq p {
    padding: 15px;
  }
}

@media screen and (max-width: 480px) {
  .faq .container {
    max-width: 95%;
    padding: 10px;
  }
  
  .price h1 {
    font-size: 30px;
  }
  
  .price h1 span {
    font-size: 16px;
  }
  
  .faq h2 {
    font-size: 0.9rem;
  }
  
  .faq button {
    padding: 10px;
  }
  
  .faq p {
    padding: 12px;
    font-size: 0.9rem;
  }
  
  .price p {
    margin: 20px 0;
  }
}