/*--------head------------*/
.head {
  padding: 20px 0;
  color: #fff;
  position: relative;
  z-index: 3;
}
.logo h1 {
  font-size: 35px;
  font-weight: 900;
}
.head .icon {
  margin-left: 10px;
}
.dropdown {
  position: relative;
}

/* Dropdown content */
.dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: rgba(255, 255, 255, 0.95);
  min-width: 200px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0;
  z-index: 1000;
}

/* Show dropdown on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Dropdown items */
.dropdown-content li {
  margin: 0 !important;
  padding: 0;
}

.dropdown-content li a {
  color: #333 !important;
  padding: 12px 20px;
  display: block;
  transition: all 0.3s ease;
  font-size: 0.95rem;
}

.dropdown-content li a:hover {
  background-color: #f5f5f5;
  color: #5d8eb2 !important;
  padding-left: 25px;
}

/* Add a small arrow indicator */
.dropdown > a::after {
  content: '▼';
  font-size: 8px;
  margin-left: 5px;
  display: inline-block;
  vertical-align: middle;
}

/* Mobile responsive styles */
@media screen and (max-width: 768px) {
  .dropdown-content {
    position: static;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: none;
    padding: 0;
    display: none;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown-content li a {
    color: #fff !important;
    padding: 10px 30px;
  }

  .dropdown-content li a:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
/*--------head------------*/
/*--------header------------*/
header {
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 30px;
  position: relative;
  z-index: 3;
}
header .nav {
  display: flex !important;
}
header li {
  margin-right: 40px;
}
header ul {
  padding: 30px 20px;
}
header ul li a {
  color: #fff;
  font-weight: 500;
  transition: 0.5s;
}
header ul li a:hover {
  color: #5d8eb2;
}
.start {
  background-color: #5d8eb2;
  padding: 30px 70px;
  color: #fff;
  clip-path: polygon(10% 0%, 100% 0, 100% 100%, 0% 100%);
}
.toggle {
  display: none;
}
/*--------header------------*/
@media screen and (max-width: 768px) {
  .start {
    clip-path: none;
  }
  header {
    margin: 0;
    background-color: #00328e96;
    position: relative;
  }
  header ul.flexSB {
    display: none;
  }
  header ul li {
    margin-bottom: 20px;
  }
  .toggle {
    display: block;
    background: none;
    color: #fff;
    font-size: 30px;
    position: absolute;
    right: 35px;
    top: 23px;
  }
  .mobile-nav {
    position: absolute;
    top: 7vh;
    left: 0;
    width: 100%;
    background-color: #00018e;
  }
}
