.coursesCard {
  padding: 50px 0;
}

.coursesCard .items {
  background-color: #fff;
  padding: 30px;
  text-align: center;
  transition: all 0.3s ease;
}

.coursesCard .img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  padding: 15px;
}

.coursesCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.coursesCard .text {
  margin-left: 15px;
  text-align: left;
}

.coursesCard .text h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
}

.coursesCard .rate {
  margin: 20px 0;
  color: #00018e;
}

.coursesCard .rate i {
  font-size: 13px;
  margin-right: 5px;
}

.coursesCard .details .dimg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: grey;
}

.coursesCard .details span {
  color: #00018e;
  font-weight: 500;
  font-size: 14px;
}

.coursesCard .price {
  margin: 30px 0;
  background-color: #f8f8f8;
  padding: 10px;
}

.coursesCard h3 {
  font-weight: 500;
  color: #00018e;
}


.margin-courses{
margin: 20%;
}

/* Grid Layouts */
.container {
  width: 90%;
  margin: auto;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1300px) {

}
/* Responsive Breakpoints */
@media screen and (max-width: 1024px) {
  .container {
    width: 95%;
  }
  
  .grid2, .grid3 {
    gap: 20px;
  }
  
  .coursesCard .text h1 {
    font-size: 24px;
    line-height: 35px;
  }
}

@media screen and (max-width: 768px) {
  .grid2, .grid3 {
    grid-template-columns: 1fr;
  }
  .margin-courses{
    margin: 100%;
    }
  .coursesCard .items {
    padding: 20px;
  }
  
  .coursesCard .content.flex {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .coursesCard .text {
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
  }
  
  .coursesCard .details .box {
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .coursesCard .details span {
    display: block;
    margin-top: 10px;
  }
  
  .online .box {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 480px) {

  .coursesCard {
    padding: 0px 0;
  }
  .coursesCard .text h1 {
    font-size: 20px;
    line-height: 30px;
  }
  
  .coursesCard .img {
    width: 60px;
    height: 60px;
    padding: 10px;
  }
  
  .coursesCard .details .dimg img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  
  .online h1 {
    font-size: 18px;
    margin: 15px 0;
  }
  
  .online span {
    font-size: 13px;
    padding: 3px 15px;
  }
}