.hero {
  background-image: url("../../../../public/images/bg.webp");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
}
.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 768px) {
  .margin {
    height: 45vh;
  }
  .hero {
    background-position: right;
    padding-top: 50%;
    height: 100vh;
  }
  .hero .row {
    width: 100%;
  }
}




/* Add overlay for better text visibility */
.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.168);
  z-index: 1;
}

.hero .container {
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.hero .row {
  max-width: 600px;
}

/* Heading styles */
.hero #heading {
  text-align: left;
  margin: 0 0 30px;
}

.hero #heading h3 {
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.hero #heading h1 {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1.2;
  margin-bottom: 20px;
}

/* Paragraph styles */
.hero p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

/* Button container */
.hero .button {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/* Button styles */
.hero button {
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.hero .primary-btn {
  background: #1eb2a6;
  border: none;
  color: white;
}

.hero .primary-btn:hover {
  background: #189e94;
}

.hero button:not(.primary-btn) {
  background: transparent;
  border: 2px solid white;
  color: white;
}

.hero button:not(.primary-btn):hover {
  background: rgba(255, 255, 255, 0.1);
}


/* Media queries for responsive design */
@media screen and (max-width: 768px) {
  .hero {
    padding: 100px 0 40px;
    background-attachment: scroll;
  }

  .hero #heading h1 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
  }

  .hero .button {
    flex-direction: column;
    gap: 15px;
  }

  .hero button {
    width: 100%;
    justify-content: center;
  }

  .margin {
    height: 50vh;
  }
}

@media screen and (max-width: 480px) {
  .hero {
    padding: 80px 0 30px;
  }

  .hero #heading h1 {
    font-size: 1.75rem;
  }

  .hero .container {
    padding: 0 15px;
    margin-top: 300px;
  }

  .hero #heading h3{
font-size: 28px;
  }
}